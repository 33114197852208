/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        var controller = new ScrollMagic.Controller({
            globalSceneOptions: {
                offset: 100,
                triggerHook: 'onleave'
            }
        });
        new ScrollMagic.Scene({offset: 150})
          .setClassToggle(".header", "sticky")
          .addTo(controller);

        // Mobile menu
        $('.sub-menu').parent().children('a').click(function(event) {
          if($(this).parent().children('.sub-menu').hasClass('open')) {
            $(this).parent().children('.sub-menu').removeClass('open');
          } else {
            $('.sub-menu').removeClass('open');
            $(this).parent().children('.sub-menu').addClass('open');
          }
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        // ==============
        // HOME SLIDER
        // ==============
        var activeItemId = parseInt('1');

        // Ajouter has no class selected
        $('[data-item-selector-toggle]').on('click', function(){
            $('[data-item-selector-toggle]').each(function(){
                $(this).removeClass('is-active');
            });
            $(this).addClass('is-active');

            var allItems        = $('[data-content-id]');
            var selectedItemId  = parseInt($(this).data('selector-id'));
            var selectedItem    = $('[data-content-id=' + selectedItemId + ']');

            allItems.each(function(){
                $(this).hide();
            })
            TweenMax.fromTo(selectedItem, .5, {x:-50, autoAlpha: 0}, {x:0, autoAlpha: 1, display:'block'});
        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
